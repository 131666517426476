<template>
  <header
    data-test-id="header-container"
    class="lg:sticky top-0 z-10"
    :class="mobileMenuIsOpen ? 'fixed w-full' : 'sticky'"
  >
    <div class="relative z-10 p-8 py-4 bg-white text-black">
      <div class="relative flex justify-between items-center">
        <div v-if="!mobileSearchOpen">
          <ul
            class="flex items-center"
          >
            <li class="flex space-x-2 pr-0">
              <RouterLink
                to="/"
                class="flex items-center"
              >
                <RemxLogo />
              </RouterLink>
            </li>
          </ul>
        </div>

        <div
          v-else
          class="w-full"
        >
          <ul class="flex w-full items-center p-0 m-0 h-6 md:space-x-6">
            <li class="block w-full">
              <SearchInput
                :set-focus="focusSearchModalOnOpen"
                @hide-search="handleCloseMobileSearch"
              />
            </li>

            <li class="flex items-center">
              <button
                class="md:hidden ml-2"
                @click="handleCloseMobileSearch"
              >
                <Icon
                  icon="XIcon"
                  class="w-6 h-6"
                />
              </button>
            </li>
          </ul>
        </div>

        <div class="hidden md:flex w-[568px] md:ml-28 lg:ml-40 xl:ml-56">
          <SearchInput :set-focus="focusSearchModalOnOpen" />
        </div>

        <!-- desktop/tablet Nav -->
        <nav class="hidden md:flex items-center">
          <ul class="flex items-center space-x-4 lg:space-x-6">
            <li
              v-if="!isAuthenticated"
              class="flex gap-4 items-center md:ml-6 lg:ml-10 xl:ml-20"
            >
              <LoadingIcon
                v-if="isLoading"
                is-dark
                class="w-5 h-5 animate-spin"
              />

              <button
                class="hidden md:inline-block border-b-2 transition-colors border-transparent translate-y-[1px] text-sm lg:text-base"
                :class="isLoading ? 'cursor-not-allowed disabled:opacity-40' : 'hover:border-brand-border'"
                :disabled="isLoading"
                @click="handleConnectWallet({ action: 'login' })"
              >
                Login
              </button>
            </li>

            <li
              v-if="!isAuthenticated"
              class="flex space-x-2"
            >
              <GlobalButton
                size="sm"
                priority="secondary-light"
                :disabled="isLoading"
                data-test-id="header-connect-wallet"
                class="outline-0"
                padding-size="sm"
                @click="handleApprovalCTAClicked(false)"
              >
                <span class="flex items-center space-x-4">
                  Create account
                </span>
              </GlobalButton>
            </li>

            <!-- Balance -->
            <li
              v-if="isAuthenticated"
              class="min-w-52 pr-4 py-2 rounded-xl ml-10"
            >
              <button
                class="w-full"
                @click="handleOpenDepositModal"
              >
                <div class="flex items-center">
                  <div class="flex flex-row mr-2">
                    <Body
                      size="sm"
                      class="text-black"
                      black-text
                    >
                      Balance
                    </Body>
                  </div>

                  <div class="flex flex-row text-center w-full relative">
                    <div class="w-[22px] h-[22px] rounded-full bg-[#1B6BFF] absolute top-[-7px] right-0 flex items-center justify-center border-2 border-white">
                      <Icon
                        icon="PlusIcon"
                        class="w-3 h-3 text-white stroke-[3px]"
                      />
                    </div>

                    <Body
                      size="sm"
                      class="text-center w-full bg-brand-bg-section rounded-full p-2 px-4 font-medium text-black"
                      black-text
                    >
                      ${{ currentBalanceUsd }}
                    </Body>
                  </div>
                </div>
              </button>
            </li>

            <!-- Avatar -->
            <li
              v-if="isAuthenticated && account"
              class="flex items-center"
            >
              <div
                v-if="isLoading"
                class="w-10 h-10 p-0.5 rounded-full shadow flex items-center justify-center overflow-hidden bg-white"
              >
                <LoadingIcon
                  is-dark
                  class="w-5 h-5 animate-spin"
                />
              </div>

              <button
                v-else
                class="group rounded-full p-0.5 transition"
                @click.stop="toggleProfileMenu"
                @keyup.enter.once="toggleProfileMenu"
              >
                <div
                  v-if="!profileMenuIsOpen"
                  class="relative"
                >
                  <span
                    v-if="chain && showNetworkStatusBanner"
                    class="bg-black border-2 border-white w-3 h-3 rounded-full absolute top-0 right-0"
                  />
                  <ul class="flex items-center space-x-6">
                    <li>
                      <div class="w-10 h-10 flex items-center justify-center overflow-hidden transition-colors hover:border-black group-focus:border-black group-focus:hover:border-black">
                        <Icon icon="MenuIcon" />
                      </div>
                    </li>
                  </ul>
                </div>

                <ProfileCloseIcon v-if="profileMenuIsOpen" />
              </button>

              <!-- Desktop Profile Menu -->
              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <div
                  v-if="profileMenuIsOpen"
                  class="hidden md:block absolute w-52 right-0 top-14 border border-brand-border bg-white rounded-2xl drop-shadow-lg"
                  @keyup.esc="closeProfileMenu"
                >
                  <NetworkStatus
                    v-if="chain && showNetworkStatusBanner"
                    :chain-id="defaultChainId"
                    :connected-chain-id="chain"
                  />

                  <div class="py-8">
                    <ul class="text-black space-y-4">
                      <li>
                        <button
                          class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section px-8"
                          @click="handleCollect2EarnApplication"
                        >
                          <Body
                            black-text
                            size="sm"
                            class="font-medium"
                          >
                            Drop Application
                          </Body>
                        </button>
                      </li>

                      <li>
                        <button
                          class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section cursor-pointer px-8"
                          @click="navigateToAndCloseMenu('contact')"
                        >
                          <Body
                            black-text
                            size="sm"
                            class="font-medium"
                          >
                            Contact
                          </Body>
                        </button>
                      </li>

                      <li>
                        <button
                          class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section cursor-pointer px-8"
                          @click="navigateToAndCloseMenu('faq')"
                        >
                          <Body
                            black-text
                            size="sm"
                            class="font-medium"
                          >
                            FAQ
                          </Body>
                        </button>
                      </li>

                      <li>
                        <button
                          class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section cursor-pointer px-8"
                          @click="navigateToAndCloseMenu('privacyPolicy')"
                        >
                          <Body
                            black-text
                            size="sm"
                            class="font-medium"
                          >
                            Privacy Policy
                          </Body>
                        </button>
                      </li>

                      <li class="pb-4">
                        <button
                          class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section cursor-pointer px-8"
                          @click="navigateToAndCloseMenu('termsOfService')"
                        >
                          <Body
                            black-text
                            size="sm"
                            class="font-medium"
                          >
                            Terms of Service
                          </Body>
                        </button>
                      </li>

                      <div class="h-px bg-brand-border" />

                      <li class="flex items-center pt-4">
                        <button
                          data-test-id="header-logout"
                          class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section px-8"
                          @click="handleLogout"
                        >
                          <Body
                            size="sm"
                            black-text
                            class="font-medium"
                          >
                            Log out
                          </Body>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </transition>
            </li>
          </ul>
        </nav>

        <!-- Mobile Nav -->
        <div class="md:hidden relative flex items-center">
          <LoadingIcon
            v-show="isLoading && !mobileSearchOpen"
            is-dark
          />

          <ul
            v-if="!mobileSearchOpen"
            class="flex items-center space-x-4"
          >
            <!-- Balance -->
            <li
              v-if="isAuthenticated"
              class="min-w-[152px] py-2 rounded-xl"
            >
              <button
                class="w-full"
                @click="handleOpenDepositModal"
              >
                <div class="flex items-center">
                  <div class="flex flex-row mr-2">
                    <Body
                      size="sm"
                      black-text
                    >
                      Balance
                    </Body>
                  </div>

                  <div class="flex flex-row text-center w-full relative">
                    <div class="w-[20px] h-[20px] rounded-full bg-[#1B6BFF] absolute top-[-6px] right-0 flex items-center justify-center border-2 border-white">
                      <Icon
                        icon="PlusIcon"
                        class="w-3 h-3 text-white stroke-[3px]"
                      />
                    </div>

                    <Body
                      size="sm"
                      class="text-center w-full bg-brand-bg-section rounded-full py-2 px-4 font-medium"
                      black-text
                    >
                      ${{ currentBalanceUsd }}
                    </Body>
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>

        <div class="block md:hidden">
          <ul class="flex items-center space-x-4">
            <li>
              <button
                v-if="!isAuthenticated && !mobileSearchOpen"
                class="text-xs transition-colors duration-300 pl-2"
                :class="[
                  isLoading ? 'cursor-not-allowed disabled:opacity-40' : 'hover:text-black',
                ]"
                :disabled="isLoading"
                @click="handleConnectWallet({ action: 'login' })"
              >
                Login
              </button>
            </li>

            <!-- Search -->
            <li
              v-if="!mobileSearchOpen"
              class="block md:hidden"
            >
              <div class="pl-0 flex items-center">
                <button @click="handleOpenMobileSearch">
                  <SearchIcon
                    class="w-4 h-6 text-black"
                  />
                </button>
              </div>
            </li>

            <li class="block md:hidden">
              <div
                v-if="!mobileSearchOpen"
                @click.stop="openMobileMenu"
              >
                <!-- Notification icon for mobile menu -->
                <!-- <span class="bg-black border-2 border-white w-3 h-3 rounded-full absolute top-[-2px] right-[-4px]" /> -->
                <Icon icon="MenuIcon" />
              </div>
            </li>
          </ul>
        </div>

        <!-- Mobile Nav menu -->
        <transition
          enter-active-class="transition duration-300 ease-[cubic-bezier(0.87, 0, 0.13, 1)]"
          enter-from-class="transform translate-y-[80vh]"
          enter-to-class="transform translate-y-0"
          leave-active-class="transition duration-300 ease-[cubic-bezier(0.5, 1, 0.89, 1)]"
          leave-from-class="transform translate-y-0"
          leave-to-class="transform translate-y-[80vh]"
        >
          <nav
            v-show="mobileMenuIsOpen"
            class="md:hidden fixed left-0 bottom-[-1px] w-full rounded-t-3xl p-8 pt-12 bg-black overflow-auto text-white text-3xl z-10"
            @click.self.stop
          >
            <button
              class="absolute top-4 right-4"
              @click="closeMobileMenu"
            >
              <Icon
                class="text-slate-400"
                icon="XIcon"
              />
            </button>

            <button
              data-featurebase-feedback
              class="absolute top-56 -right-7 bg-white px-4 py-2 rounded-t-md -rotate-90"
            >
              <Body size="xs">
                Feedback
              </Body>
            </button>

            <div
              v-if="isAuthenticated"
              class="mb-12"
            >
              <div
                class="w-12 h-12 rounded-full shadow flex items-center justify-center overflow-hidden border-slate-800 border-2  bg-slate-400"
              >
                <img
                  v-if="icon"
                  class="w-12 h-12 object-cover"
                  :src="getImage(icon, { resize: { width: 100, fit: 'cover' } })"
                >

                <ProfileIcon
                  v-else
                  class="w-12 h-12"
                />
              </div>
            </div>

            <div class="max-h-[400px] overflow-auto">
              <ul class="flex flex-col w-full gap-4">
                <li @click="closeMobileMenu">
                  <button
                    class="w-full flex gap-x-2 rounded-md text-xl cursor-pointer"
                    @click="navigateToAndCloseMenu('me-analytics')"
                  >
                    <Heading
                      class="text-white border-transparent hover:border-white transition-colors duration-300 border-b-2 inline"
                      dark-background
                      :level="2"
                    >
                      Analytics
                    </Heading>
                  </button>
                </li>

                <li @click="closeMobileMenu">
                  <button
                    class="w-full flex gap-x-2 rounded-md text-xl cursor-pointer "
                    @click="handleCollect2EarnApplication"
                  >
                    <Heading
                      class="text-white border-transparent hover:border-white transition-colors duration-300 border-b-2 inline"
                      dark-background
                      :level="2"
                    >
                      Drop Application
                    </Heading>
                  </button>
                </li>

                <li @click="closeMobileMenu">
                  <button
                    class="w-full flex gap-x-2 rounded-md text-xl cursor-pointer"
                    @click="navigateToAndCloseMenu('faq')"
                  >
                    <Heading
                      class="text-white border-transparent hover:border-white transition-colors duration-300 border-b-2 inline"
                      dark-background
                      :level="2"
                    >
                      FAQ
                    </Heading>
                  </button>
                </li>

                <li @click="closeMobileMenu">
                  <button
                    class="w-full flex gap-x-2 rounded-md text-xl cursor-pointer"
                    @click="navigateToAndCloseMenu('privacyPolicy')"
                  >
                    <Heading
                      class="text-white border-transparent hover:border-white transition-colors duration-300 border-b-2 inline"
                      dark-background
                      :level="2"
                    >
                      Privacy Policy
                    </Heading>
                  </button>
                </li>

                <li @click="closeMobileMenu">
                  <button
                    class="w-full flex gap-x-2 rounded-md text-xl cursor-pointer"
                    @click="navigateToAndCloseMenu('termsOfService')"
                  >
                    <Heading
                      class="text-white border-transparent hover:border-white transition-colors duration-300 border-b-2 inline"
                      dark-background
                      :level="2"
                    >
                      Terms of Service
                    </Heading>
                  </button>
                </li>

                <!-- <li
                  v-if="isAuthenticated && isAdmin"
                  @click="closeMobileMenu"
                >
                  <button
                    class="w-full flex gap-x-2 rounded-md text-xl cursor-pointer"
                    @click="navigateToAndCloseMenu('create-weekly-sponsor')"
                  >
                    <Heading
                      class="text-white border-transparent hover:border-white transition-colors duration-300 border-b-2 inline"
                      dark-background
                      :level="2"
                    >
                      Add Weekly Sponsor
                    </Heading>
                  </button>
                </li> -->

                <li v-if="isAuthenticated">
                  <button
                    class="w-full flex gap-x-2 rounded-md text-xl cursor-pointer"
                    @click="handleLogout"
                  >
                    <Body
                      class="text-brand-placeholder-dark border-transparent hover:border-white transition-colors duration-300 border-b-2 inline"
                      size="base"
                    >
                      Log out
                    </Body>
                  </button>
                </li>

                <li>
                  <!-- Social Links -->
                  <ul class="flex w-full md:items-center space-x-1 flex-row">
                    <li>
                      <button
                        class="inline-block p-2 hover:bg-brand-bg-section rounded-lg -ml-2"
                        @click="navigateToSocialAndCloseMenu('twitter')"
                      >
                        <XPlatformIcon
                          class="w-6 h-6"
                        />
                      </button>
                    </li>

                    <li>
                      <button
                        class="inline-block p-2 hover:bg-brand-bg-section rounded-lg"
                        @click="navigateToSocialAndCloseMenu('instagram')"
                      >
                        <InstagramIcon class="w-6 h-6" />
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <ul class="w-full mt-12">
              <li
                v-if="!isAuthenticated"
                class="flex flex-col space-y-4"
              >
                <GlobalButton
                  full-width
                  priority="primary-dark"
                  :disabled="isLoading"
                  @click="handleConnectWallet({ event: $event, mobile: true, action: 'login'})"
                >
                  <span class="flex items-center space-x-4 justify-center">
                    Login
                  </span>
                </GlobalButton>

                <GlobalButton
                  full-width
                  priority="secondary-dark"
                  :disabled="isLoading"
                  @click="handleApprovalCTAClicked(true)"
                >
                  <span class="flex items-center space-x-4 justify-center">
                    Create account
                  </span>
                </GlobalButton>
              </li>

              <li v-if="isAuthenticated">
                <GlobalButton
                  data-test-id="header-logout"
                  priority="secondary-light"
                  full-width
                  @click="handleCreateMoment"
                >
                  Create moment
                </GlobalButton>
              </li>
            </ul>
          </nav>
        </transition>

        <transition
          enter-active-class="transition duration-300 ease-[cubic-bezier(0.87, 0, 0.13, 1)]"
          enter-from-class="opacity-0"
          enter-to-class="transform translate-y-0 opacity-100"
          leave-active-class="transition duration-400 ease-[cubic-bezier(0.5, 1, 0.89, 1)]"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-show="mobileMenuIsOpen"
            class="fixed inset-0 bg-black/60 backdrop-blur-sm md:hidden z-0"
            @click="closeMobileMenu"
          />
        </transition>
      </div>
    </div>

    <div
      v-show="showFarcasterAddToAccountAnnouncement && isAuthenticated && !showConnectXProfileAnnouncement"
      id="announcement-farcaster-add-to-account-banner"
      class="z-10 pb-0 md:pb-6"
    >
      <AnnouncementBanner
        class="rounded-none"
        priority="announcement-farcaster"
        :action="{ buttonName: 'Get started', buttonAction: handleAnnouncementFarcasterAddToAccountPrimaryAction }"
        :secondary-action="{ buttonName: 'Dismiss', buttonAction: handleAnnouncementFarcasterSecondaryAction }"
      >
        Connect your Farcaster account to grow your following and share your Remx profile with your Warpcast friends.
      </AnnouncementBanner>
    </div>

    <div
      v-show="(!showFarcasterAddToAccountAnnouncement && showFarcasterShareProfileAnnouncement) && isAuthenticated && !showConnectXProfileAnnouncement"
      id="announcement-farcaster-share-profile-banner"
      class="z-10 pb-0 md:pb-6"
    >
      <AnnouncementBanner
        class="rounded-none"
        priority="announcement-farcaster"
        :action="{ buttonName: 'Share profile', buttonAction: handleAnnouncementFarcasterShareProfilePrimaryAction }"
        :secondary-action="{ buttonName: 'Dismiss', buttonAction: () => handleAnnouncementFarcasterSecondaryAction(false) }"
      >
        Grow your following on Remx. Share your profile on Warpcast.
      </AnnouncementBanner>
    </div>

    <div
      v-show="showConnectXProfileAnnouncement && isAuthenticated && isFlagEnabled('VITE_FEATURE_X_CONNECT')"
      id="announcement-x-add-to-account-banner"
      class="z-10 pb-0 md:pb-6"
    >
      <AnnouncementBanner
        class="rounded-none"
        priority="announcement-x"
        :action="{ buttonName: 'Get started', buttonAction: handleAnnouncementConnectXProfilePrimaryAction }"
        :secondary-action="{ buttonName: 'Dismiss', buttonAction: handleAnnouncementConnectXProfileSecondaryAction }"
      >
        Connect your X profile on Remx.
      </AnnouncementBanner>
    </div>

    <CreateMomentUnauthorizedModal
      :is-open="isCreateMomentUnauthorizedModalOpen"
      @close="isCreateMomentUnauthorizedModalOpen = false"
    />
  </header>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted, onUnmounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useGtag } from 'vue-gtag-next'

import { stripTypeNameFromObject } from '#utils/global'

import { useMutation } from '#composables/use-apollo'
import { useLoginModal } from '#composables/use-login-modal'
import useProviders from '#composables/use-providers'
import { useSupportedNetworks } from '#composables/use-supported-networks'
import useImage from '#composables/use-image'
import useAccount from '#composables/use-account'
import useFeatureFlag from '#composables/use-feature-flag'
import useLogin from '#composables/use-login'
import useConnectX from '#composables/use-connect-x'
import { useBalance } from '#composables/use-balance'

import { UPDATE_ACCOUNT } from '#mutations/UpdateAccount'
import type { UpdateAccount, UpdateAccountVariables } from '#graphql/types/UpdateAccount'

import GlobalButton from '#components/global/global-button'
import Icon from '#components/icon.vue'
import Body from '#components/typography/body'
import Heading from '#components/typography/heading'
import LoadingIcon from '#components/icons/loading-icon.vue'
import NetworkStatus from '#components/network-status'
import ProfileCloseIcon from '#components/icons/profile-close-icon.vue'
import ProfileIcon from '#components/icons/profile-icon.vue'
import RemxLogo from '#components/logos/remx-logo/remx-logo.vue'
import SearchInput from '#components/search/search-input.vue'
import SearchIcon from '#components/icons/search-icon.vue'
import AnnouncementBanner from '#components/banner/banner.vue'
import CreateMomentUnauthorizedModal from '#components/global/create-moment-unauthorized-modal.vue'
import XPlatformIcon from '#components/icons/x-platform-icon.vue'

import { InstagramIcon } from '@creativelayer/chalk-icons'

defineProps({
  mobileMenuIsOpen: {
    type: Boolean,
    default: false,
  },

  profileMenuIsOpen: {
    type: Boolean,
    default: false,
  },

  isCreateNav: {
    type: Boolean,
    default: false,
  },

  isShowroom: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['openMobileMenu', 'closeMobileMenu', 'closeProfileMenu', 'toggleProfileMenu', 'slideToWaitList', 'openLoginModal', 'openDepositModal'])

const {
  id,
  send: sendAuth,
  account,
  isAuthenticated,
  isLoading,
  logout,
  fetchAccount,
  accountId,
  isAdmin,
} = useLogin()
const { event } = useGtag()
const { chain } = useProviders()
const { defaultChainId } = useSupportedNetworks()
const {
  profileUsername,
  icon,
  accountNotifications,
  hasFarcaster,
  hasTwitterProfile,
  isCreator,
} = useAccount()
const { getImage } = useImage()
const { send: sendConnectModal } = useLoginModal()
const router = useRouter()
const route = useRoute()
const { isFlagEnabled } = useFeatureFlag()
const { openConnectXModal } = useConnectX()
const { waitForBalance, currentBalanceUsd } = useBalance()

const isLogIn = ref(false)
const isCreateAccount = ref(false)
const isCreateMomentUnauthorizedModalOpen = ref(false)
const waitOnLoginFromCta = ref(false)
const waitOnRedirect = ref(false)
const mobileSearchOpen = ref(false)
const focusSearchModalOnOpen = ref(false)
const showFarcasterAddToAccountAnnouncement = ref(false)
const showFarcasterShareProfileAnnouncement = ref(false)
const showConnectXProfileAnnouncement = ref(false)
const showCollectableMomentsUpdateAnnouncement = ref(true)
const waitForBalanceInterval = ref()

const hasFarcasterAccount = computed(() => hasFarcaster.value)
// show network status banner whenever users are connected to a test network
const showNetworkStatusBanner = computed(() => isAuthenticated && (![1, 137].includes(defaultChainId.value as number)))

const handleOpenMobileSearch = () => {
  focusSearchModalOnOpen.value = true
  mobileSearchOpen.value = true
}

const handleCloseMobileSearch = () => {
  mobileSearchOpen.value = false
  focusSearchModalOnOpen.value = false
}

const handleCreateMoment = () => {
  if (!isCreator.value && !isAdmin.value) {
    isCreateMomentUnauthorizedModalOpen.value = true
  } else {
    router.push({ name: 'moment-create', params: { profileSlug: account.value.slug || '' } })
  }

  closeProfileMenu()
  closeMobileMenu()
}

const openMobileMenu = () => {
  emit('openMobileMenu')
  document.body.classList.add('overflow-hidden')
}

const closeMobileMenu = () => {
  emit('closeMobileMenu')
  document.body.classList.remove('overflow-hidden')
}

const closeProfileMenu = () => emit('closeProfileMenu')
const toggleProfileMenu = () => emit('toggleProfileMenu')

const handleCollect2EarnApplication = () => {
  const collect2EarnApplicationUrl = `https://d674ncbpli8.typeform.com/to/DtsUCzH0`

  window.open(collect2EarnApplicationUrl, '_blank')
  closeProfileMenu()
}

const navigateToAndCloseMenu = (name: string) => {
  navigateTo(name)

  closeProfileMenu()
}

const navigateToSocialAndCloseMenu = (name: string) => {
  if (name === 'twitter') {
    window.open('https://twitter.com/remx_xyz', '_blank')
  } else if (name === 'instagram') {
    window.open('https://www.instagram.com/remx_xyz/', '_blank')
  }

  closeProfileMenu()
}

const navigateTo = (name: string) => router.push({ name: name, params: { profileSlug: account.value.slug || '' } })

const handleLogout = async () => {
  logout()

  event('logout', {
    'event_category': 'engagement',
  })
}

const handleConnectWallet = (options: { event?: Event, mobile?: boolean, action?: 'login' | 'signup' | 'add-wallet' }) => {
  if (options?.action) {
    sendConnectModal({ type: 'SET_ACTION', data: options.action })

    if (options.action === 'signup') {
      sendAuth('SIGN_UP')
    } else {
      sendAuth('SIGN_IN')
    }

    // This is to keep track of if 'Log in' or 'Create account' was clicked so
    // we can show just one loading icon since the two buttons use the same
    // loading state
    if (options.action === 'login') {
      if (isCreateAccount.value) {
        isCreateAccount.value = false
      }

      isLogIn.value = true
    } else if (options.action === 'signup') {
      if (isLogIn.value) {
        isLogIn.value = false
      }

      isCreateAccount.value = true
    }
  }

  openConnectModal(options?.event, options?.mobile)
}

const openConnectModal = (_: Event | '' | undefined, mobile = false) => {
  if (mobile) {
    closeMobileMenu()
  }

  sendConnectModal('OPEN')

  event('connect_wallet', {
    'event_category': 'engagement',
  })
}

const handleApprovalCTAClicked = (mobile = false) => {
  if (!isAuthenticated.value) {
    const previousUrl = route.path as string

    if (mobile) {
      closeMobileMenu()
    }

    router.push({ name: 'start-create-account', query: { page: previousUrl } })
  } else {
    approvalCheck(false)
  }
}

const approvalCheck = async (refetchAccount:boolean) => {
  waitOnRedirect.value = true

  if (refetchAccount) {
    try {
      await fetchAccount()
    } catch (error) {
      console.error('error', error)
      waitOnRedirect.value = false
    }
  }

  router.push({ name: 'profile-edit', params: { profileSlug: account.value.slug || '' } })
}

const handleOpenDepositModal = () => emit('openDepositModal')

const handleAnnouncementFarcasterAddToAccountPrimaryAction = () => { router.push({ name: 'profile-edit', params: { profileSlug: profileUsername.value || '' }, hash: '#connected-apps', query: { showFarcaster: 'true' } })}

// Update Account
const {
  mutate: updateAccount,
  loading: updateAccountLoading,
  onResult: updateAccountOnResult,
  onError: updateAccountOnError,
} = useMutation<UpdateAccount, UpdateAccountVariables>(UPDATE_ACCOUNT)

updateAccountOnError((error) => {
  console.error('Error updating account:', error)
})

const handleAnnouncementFarcasterSecondaryAction = (isAddToAccount = true) => {
  showFarcasterAddToAccountAnnouncement.value = false

  if (!isAddToAccount) {
    showFarcasterShareProfileAnnouncement.value = false
  }

  // update farcaster announcement dismissed notifications
  const input = {
    id: accountId.value,
    notifications: {
      ...accountNotifications.value,
      farcaster: {
        addToAccountDismissed: true,
        shareProfileDismissed: isAddToAccount ? false : true,
      },
    },
  }

  delete input.notifications['__typename']

  updateAccount({ input })
}

const handleAnnouncementFarcasterShareProfilePrimaryAction = () => {
  const warpcastUrl = `https://warpcast.com/~/compose?text=Join%20me%20on%20remx.xyz%20to%20earn%20rewards%20for%20collecting%20art:%0A%0Aremx.xyz/${profileUsername.value}&embeds[]=https://remx.xyz/${profileUsername.value}/drops`

  window.open(warpcastUrl, '_blank')

  // update farcaster shareProfile dismissed notification
  const input = {
    id: accountId.value,
    notifications: {
      ...accountNotifications.value,
      farcaster: {
        addToAccountDismissed: true,
        shareProfileDismissed: true,
      },
    },
  }

  delete input.notifications['__typename']

  updateAccount({ input })
}

const handleAnnouncementConnectXProfileSecondaryAction = () => {
  showConnectXProfileAnnouncement.value = false

  // update connect x profile dismissed notification
  const input = {
    id: accountId.value,
    notifications: {
      ...stripTypeNameFromObject(accountNotifications.value),
      xProfileDismissed: true,
    },
  }

  updateAccount({ input })
}

const handleAnnouncementConnectXProfilePrimaryAction = () => {
  openConnectXModal()
}


watch(
  [isAuthenticated, id],
  async ([newIsAuthenticated, newId]) => {
    if (newIsAuthenticated && waitOnLoginFromCta.value && newId !== null) {
      waitOnLoginFromCta.value = false
      approvalCheck(true)
    }
    if (newIsAuthenticated && newId !== null) {
      await waitForBalance()
    }
  }
)

watch(() => [hasFarcasterAccount.value, accountNotifications.value, hasTwitterProfile.value], ([hasFarcasterAccount, accountNotifications, hasTwitterProfile]) => {
  showFarcasterAddToAccountAnnouncement.value = !hasFarcasterAccount && (accountNotifications?.farcaster && !accountNotifications.farcaster.addToAccountDismissed)

  showFarcasterShareProfileAnnouncement.value = hasFarcasterAccount && (accountNotifications?.farcaster && !accountNotifications.farcaster.shareProfileDismissed)

  showConnectXProfileAnnouncement.value = !accountNotifications?.xProfileDismissed && !hasTwitterProfile && isFlagEnabled('VITE_FEATURE_X_CONNECT')

  showCollectableMomentsUpdateAnnouncement.value = !accountNotifications?.collectableMomentsUpdateDismissed
}, { immediate: true })

onMounted(() => {
  waitForBalanceInterval.value = setInterval(() => {
    waitForBalance()
  }, 60000)
})

onUnmounted(() => {
  if (waitForBalanceInterval.value) {
    clearInterval(waitForBalanceInterval.value)
  }

  waitForBalanceInterval.value = null
})
</script>
